/* eslint-disable react/button-has-type */
import type { ColorsEnum } from 'app.types';
import cn from 'classnames';
import React from 'react';

import ALink from 'components/basic/ALink';
import type { ButtonTypes } from 'components/basic/Button/types';
import { CircularProgress } from 'components/basic/Loaders';

import s from './Button.module.scss';

export type ButtonSizes = 'small' | 'medium' | 'large';
export type ButtonLooks = ColorsEnum;

const Button: React.FC<ButtonTypes> = props => {
  const {
    look = 'purple',
    isDark = false,
    type = 'button',
    children,
    className,
    href,
    isDisabled,
    isLoading,
    onClick,
    grow,
    size,
    ...restProps
  } = props;
  const classes = cn(
    s.button,
    s[`${look}${isDark ? 'Dark' : ''}`],
    {
      [s.grow]: grow,
      [s.small]: size === 'small',
    },
    className,
  );

  if (href) {
    return (
      <ALink href={href} className={classes} role="button" onClick={onClick}>
        <span>{isLoading ? <CircularProgress /> : children}</span>
      </ALink>
    );
  }

  return (
    <button
      className={classes}
      onClick={onClick}
      type={type}
      disabled={isDisabled || isLoading}
      {...restProps}
    >
      <span>{isLoading ? <CircularProgress /> : children}</span>
    </button>
  );
};

export default Button;
